import React from 'react';
import Link from 'components/link';
import Button from 'components/control/button';
import MapIcon from 'components/icon/map-icon';
import { buildClassName } from 'utils/build-class-name';
import { trackEvent } from 'utils/google-tag-manager';
import styles from '../../style.module.scss';
import { useUserContext, usePreferencesContext } from 'contexts';
import { testIds } from 'constants/test-constants';
import { GTM_CLICK_HOME_PAGE_LINKED_TEXT_BUTTON,
  GTM_CLICK_HOME_PAGE_FOR_SALE_VIEW_MAP_BUTTON } from 'constants/events';
import replaceFrenchAccents from 'utils/replace-french-accents';
import dynamic from 'next/dynamic';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';

import type Listing from 'data/listing';
import type { ListingCardData } from 'components/listing-card';

const ListingCardCarousel = dynamic(() => import('components/listing-card-carousel'));

export interface Props {
  isCrawler: boolean;
  availableHomeListings: ListingCardData[];
  availableHomeListingsMeta: number;
  availableHomeListingsLocation: string;
  isExpandedArea?: boolean;
  homesForSaleUrl: string;
  mapViewUrl: string;
}

export default function ZoocasaForSaleSection({ isCrawler, availableHomeListings, availableHomeListingsMeta, availableHomeListingsLocation, isExpandedArea, homesForSaleUrl, mapViewUrl }: Props) {
  const { userLocation } = useUserContext();
  const { listingParams, lastSearchLocation } = usePreferencesContext();

  const currentLocation = lastSearchLocation?.description || userLocation?.name;

  const setMapSlug = () => {
    listingParams.setAreaName(lastSearchLocation?.description || userLocation?.name || '');
  };

  return (
    <section className={buildClassName(styles['home-sections'], styles.available)}>
      <div className={styles['home-block']} data-testid={testIds.availableHomeListings}>
        <div className={styles.row}>
          <>
            <Link href={replaceFrenchAccents(homesForSaleUrl)} onClick={() => trackEvent(GTM_CLICK_HOME_PAGE_LINKED_TEXT_BUTTON)}>
              <h2 data-testid={testIds.homesForSaleLink}>{`Homes for Sale ${isExpandedArea ? 'Near' : 'In'} ${capitalizeWords(availableHomeListingsLocation)}`}</h2>
            </Link>
            <p className={styles.label} data-testid={testIds.homesForSaleLocation}>Current Location: {capitalizeWords(currentLocation)} </p>
          </>
          <Link href={mapViewUrl}>
            <Button label="Map View" Icon={MapIcon} theme="secondary" testId={testIds.homesForSaleMapView}
              onClick={()=>{
                trackEvent(GTM_CLICK_HOME_PAGE_FOR_SALE_VIEW_MAP_BUTTON);
                setMapSlug();}} />
          </Link>
        </div>
        <ListingCardCarousel listingCardsData={{ listings: availableHomeListings as Listing[], type: 'available', userLocation, redirectUrl: replaceFrenchAccents(homesForSaleUrl), isCTAIncluded: true, listingsTotalCount: availableHomeListingsMeta || 0 }} isCrawler={isCrawler}/>
      </div>
    </section>
  );
}
