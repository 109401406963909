import React, { useState } from 'react';
import { useIsMobile } from 'hooks/use-size-class';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';

import type { LinkDataType } from '../internal-links';

interface Props {
  isCrawler?: boolean;
  data?: LinkDataType[];
}

export default function InternalLinksExp({ isCrawler = false, data }: Props) {
  const isMobile = useIsMobile();
  const [activeCategory, setActiveCategory] = useState(0);
  const activeGroup = data![activeCategory];

  const groups = data?.map((group, index) => {
    const displayLink = (isMobile && activeCategory === index) || isCrawler;
    const links = displayLink ? group.links.map((link, index) => (
      <Link key={index} href={link.link}>{link.label}</Link>
    )) : <></>;
    return (
      <div key={index} className={buildClassName(styles['categories-container'])}>
        <div className={buildClassName(styles['category-link'])}>
          <a><h3 onClick={() => setActiveCategory(index)} className={buildClassName(index === activeCategory ? styles.active : '')} data-testid={testIds.internalLink}>
            {group.title}
          </h3></a>
        </div>
        <div className={buildClassName(styles['links-container'])}>
          {links}
        </div>
      </div>
    );
  });

  return (
    <div className={buildClassName(styles.component)} data-testid={testIds.internalLinksContainer}>
      <div className={styles.container}>
        <div className={styles.groups}>
          {groups}
        </div>
        {!isMobile &&
          <div className={buildClassName(styles['links-container'])}>
            {activeGroup.links.map((link, index) => (
              <Link key={index} href={link.link} target={'_blank'}>{link.label}</Link>
            ))}
          </div>
        }
      </div>
    </div>
  );
}
