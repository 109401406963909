import React, { useEffect, useRef, useState } from 'react';
import HeaderSearch from 'components/home-page/header-search';
import Link from 'components/link';
import Layout from 'components/layout';
import useQueryParams from 'hooks/use-query-params';
import { useModalContext } from 'contexts/modal';
import Button from 'components/control/button';
import { buildClassName } from 'utils/build-class-name';
import { trackEvent } from 'utils/google-tag-manager';
import styles from '../style.module.scss';
import DEFAULT_LISTING_PARAMS from 'contexts/preferences/listing-params/defaults';
import data from 'components/home-page/internal-links/data.json';
import NotificationBanner from 'components/notification-banner';
import { useUserContext, usePreferencesContext } from 'contexts';
import { useThemeContext } from 'contexts/theme';
import { CountryCodeList } from 'types/countries';
import { testIds } from 'constants/test-constants';
import { generateSlugAndCoordinatesFromLastSearch, generateUrlsFromLastSearch, LastSearchSlugAndCoordsResponse, LastSearchUrlsResponse } from 'data/search-predictions';
import { GTM_CLICK_HOMEPAGE_SOLD_BUTTON, GTM_CLICK_HOMEPAGE_MARKET_INSIGHTS_BUTTON,
  GTM_CLICK_HOMEPAGE_FEATURED_BUTTON, GTM_CLICK_HOMEPAGE_BUYERS_CARD_CLICK, GTM_CLICK_HOMEPAGE_BUYERS_BUTTON_CLICK,
  GTM_CLICK_HOMEPAGE_SELLERS_CARD_CLICK, GTM_CLICK_HOMEPAGE_SELLERS_BUTTON_CLICK } from 'constants/events';
import { ModalOpeners } from 'types/modal';
import replaceFrenchAccents from 'utils/replace-french-accents';
import LoadWhenVisible from 'components/load-when-visible';
import LoadingSkeleton from 'components/loading-skeleton';
import dynamic from 'next/dynamic';
import ZoocasaForSaleSection from '../themed-for-sale-section/zoocasa';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';
import InternalLinksContainer from '../internal-links/internalLinksContainer';

import type { HomePageProps } from '../index';

export default function HomePageZoocasa({ isCrawler, featuredHomesMlsNums, isNotificationBannerHidden, availableHomeListings, availableHomeListingsMeta, availableHomeListingsLocation, isExpandedArea, internalLinksData }: HomePageProps) {
  const { userLocation, siteLocation, setIsOnSignUpUrl, isAuthenticated } = useUserContext();
  const { lastSearchLocation } = usePreferencesContext();
  const { openModal } = useModalContext();
  const { theme } = useThemeContext();
  const pageRef = useRef(null);

  const [homesForSaleUrl, setHomesForSaleUrl] = useState(`/${siteLocation?.toLowerCase()}-real-estate`);
  const [soldPricesUrl, setSoldPricesUrl] = useState(`/${siteLocation?.toLowerCase()}-real-estate/sold`);
  const [mapViewUrl, setMapViewUrl] = useState(`/search?latitude=${DEFAULT_LISTING_PARAMS.filter.latitude}&longitude=${DEFAULT_LISTING_PARAMS.filter.longitude}`);
  const [isSoldHomesVisible, setIsSoldHomesVisible] = useState(false);
  const [isKeywordsListVisible, setIsKeywordsListVisible] = useState(false);
  const [isFeaturedHomesVisible, setIsFeaturedHomesVisible] = useState(false);
  const [isBlogVisible, setIsBlogVisible] = useState(false);
  const [isContentBlockVisible, setIsContentBlockVisible] = useState(false);
  const [currentSlug, setCurrentSlug] = useState(DEFAULT_LISTING_PARAMS.filter.slug);
  const [currentAreaName, setCurrentAreaName] = useState(DEFAULT_LISTING_PARAMS.filter.areaName);
  const [currentLatitude, setCurrentLatitude] = useState(DEFAULT_LISTING_PARAMS.filter.latitude);
  const [currentLongitude, setCurrentLongitude] = useState(DEFAULT_LISTING_PARAMS.filter.longitude);
  const [currentSublocation, setCurrentSublocation] = useState('');
  const [HomeListingsModule, setHomeListingsModule] = useState<any>();
  const [Blog, setBlog] = useState<any>();
  const [KeywordsList, setKeywordsList] = useState<any>();
  const [ContentBlock, setContentBlock] = useState<any>();
  const isCanadianSite = siteLocation === CountryCodeList.CANADA;

  const generateCoordinatesAndSlug = async () => {
    if (lastSearchLocation) {
      generateSlugAndCoordinatesFromLastSearch(lastSearchLocation).then((response: LastSearchSlugAndCoordsResponse) => {
        setCurrentSlug(response?.slug || currentSlug);
        setCurrentAreaName(lastSearchLocation.description);
        setCurrentLatitude(response?.latitude || currentLatitude);
        setCurrentLongitude(response?.longitude || currentLongitude);
        setCurrentSublocation(response?.subLocation);
      });
    }
    else if (userLocation && userLocation.countryCode === 'US') {
      setCurrentSlug(userLocation.slug);
      setCurrentAreaName(userLocation.name);
      setCurrentLatitude(userLocation.latitude);
      setCurrentLongitude(userLocation.longitude);
      setCurrentSublocation('');
    }
    else {
      setCurrentSlug(isCanadianSite ? defaultCACityPayload.slug : defaultUSCityPayload.slug);
      setCurrentAreaName(isCanadianSite ? defaultCACityPayload.name : defaultUSCityPayload.name);
      setCurrentLatitude(isCanadianSite ? defaultCACityPayload.latitude : defaultUSCityPayload.latitude);
      setCurrentLongitude(isCanadianSite ? defaultCACityPayload.longitude : defaultUSCityPayload.longitude);
      setCurrentSublocation('');
    }
  };
  
  const generateUrls = async () => {
    if (lastSearchLocation) {
      generateUrlsFromLastSearch(lastSearchLocation).then((response: LastSearchUrlsResponse) => {
        setHomesForSaleUrl(response?.homesForSaleUrl || homesForSaleUrl);
        setSoldPricesUrl(response?.soldPricesUrl || soldPricesUrl);
        setMapViewUrl(response?.mapViewUrl || mapViewUrl);
      });
    } else if (userLocation) {
      const updatedHomeUrl = `/${userLocation.slug}-real-estate`;
      setHomesForSaleUrl(updatedHomeUrl);
      setSoldPricesUrl(`${updatedHomeUrl}/sold`);
      setMapViewUrl(`/search?latitude=${userLocation?.latitude}&longitude=${userLocation?.longitude}`);
    }
  };

  const generateInternalLinks = () => {
    if (siteLocation === CountryCodeList.UNITED_STATES) {
      return data['usa-links'];
    } else if (siteLocation === CountryCodeList.CANADA) {
      return data['canadian-links'];
    }
  };

  useQueryParams(params => openModal('reset-password', params), ModalOpeners.RESET_PASSWORD_TOKEN, ModalOpeners.EMAIL);
  useQueryParams(params => openModal('confirm-account', params), ModalOpeners.CONFIRMATION_TOKEN, ModalOpeners.USER_AGENT);
  useQueryParams(params => openModal('login-registration', params), ModalOpeners.SIGNIN_EMAIL);
  useQueryParams(params => openModal('login-registration', params), ModalOpeners.WARNING_MESSAGE);
  useQueryParams(params => openModal('notification', { message: params.notification as string }), ModalOpeners.NOTIFICATION);
  
  useEffect(() => {
    const isLoginUrl = window.location.href.endsWith('#sign-up');
    if (isLoginUrl && !isAuthenticated) {
      setIsOnSignUpUrl(true);
      openModal('login-registration');
      // Trigger a click event on the screen otherwise modal will not open
      setTimeout(() => {
        pageRef.current?.click();
      }, 0);
    }
    else {
      setIsOnSignUpUrl(false);
    }
    
    generateUrls();
    generateCoordinatesAndSlug();
    trackEvent('UiHomePView');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSoldHomesVisible && !HomeListingsModule) {
      setHomeListingsModule(dynamic(import('components/home-page/home-modules')));
    }
    if (isFeaturedHomesVisible && !HomeListingsModule) {
      setHomeListingsModule(dynamic(import('components/home-page/home-modules')));
    }
    if (isKeywordsListVisible && !KeywordsList) {
      setKeywordsList(dynamic(import('components/home-page/keywords-list')));
    }
    if (isBlogVisible && !Blog) {
      setBlog(dynamic(import('components/home-page/blog')));
    }
    if (isContentBlockVisible && !ContentBlock) {
      setContentBlock(dynamic(import('components/home-page/content-block')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoldHomesVisible, isFeaturedHomesVisible, isKeywordsListVisible, isBlogVisible, isContentBlockVisible]);

  return (
    <Layout>
      <NotificationBanner label="New Construction Homes are here!" link="/newhomes" isHidden={isNotificationBannerHidden} />
      <HeaderSearch />
      <section className={styles.component} ref={pageRef}>
        <div className={styles['home-modules']}>
          <ZoocasaForSaleSection isCrawler={isCrawler} availableHomeListings={availableHomeListings} availableHomeListingsMeta={availableHomeListingsMeta} availableHomeListingsLocation={availableHomeListingsLocation} isExpandedArea={isExpandedArea} homesForSaleUrl={homesForSaleUrl} mapViewUrl={mapViewUrl} />
          <section className={buildClassName(styles['home-sections'], styles.sold, !isCanadianSite && styles.full)}>
            <LoadWhenVisible onValueChange={() => setIsSoldHomesVisible(true)}>
              {!isSoldHomesVisible && <LoadingSkeleton />}
              {isSoldHomesVisible && HomeListingsModule &&
                <div className={styles['home-block']} data-testid={testIds.soldHomeListings}>
                  <div className={styles.row}>
                    <Link href={replaceFrenchAccents(soldPricesUrl)} testId={testIds.soldPricesButtonLink} onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_SOLD_BUTTON)}>
                      <h2>Sold Prices</h2>
                    </Link>
                    <Link href={replaceFrenchAccents(soldPricesUrl)}>
                      <Button label="More Data" theme="secondary" expHomePadding={false} onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_SOLD_BUTTON)} testId={testIds.moreDataButton} />
                    </Link>
                    <p className={styles.label}>Search Sold Data from 2003 - {new Date().getFullYear()}</p>
                  </div>
                  <HomeListingsModule type="sold" userLocation={userLocation} redirectUrl={replaceFrenchAccents(soldPricesUrl)} isCrawler={isCrawler}/>
                </div>
              }
            </LoadWhenVisible>
          </section>
          {isCanadianSite &&
            <section className={buildClassName(styles['home-sections'], styles['market-insights'])}>
              <LoadWhenVisible onValueChange={() => setIsBlogVisible(true)}>
                {!isBlogVisible && <LoadingSkeleton />}
                {isBlogVisible && Blog &&
                  <div className={styles['home-block']} data-testid={testIds.marketInsightsWidget}>
                    <div className={styles.row}>
                      <a href="https://www.zoocasa.com/blog/" data-testid={testIds.marketInsightsLink}><h2 onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_MARKET_INSIGHTS_BUTTON)}>Market Insights</h2></a>
                      <a href="https://www.zoocasa.com/blog/" data-testid={testIds.marketInsightsMoreNews}><Button label="More News" theme="secondary" onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_MARKET_INSIGHTS_BUTTON)} /></a>
                      <p className={styles.label}>Read the latest real estate news and reports</p>
                    </div>
                    <Blog />
                  </div>
                }
              </LoadWhenVisible>
            </section>
          }
        </div>
        <div className={styles['home-modules']}>
          {isCanadianSite &&
            <section className={buildClassName(styles['home-sections'], styles['featured-homes'])}>
              <LoadWhenVisible onValueChange={() => setIsFeaturedHomesVisible(true)}>
                {!isFeaturedHomesVisible && <LoadingSkeleton/>}
                {isFeaturedHomesVisible && HomeListingsModule &&
                  <div className={styles['home-block']} data-testid={testIds.featuredHomesListings}>
                    <div className={styles.row}>
                      <Link href="/ca-real-estate/houses" onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_FEATURED_BUTTON)}>
                        <h2 data-testid={testIds.featuredHomesLink}>Featured Homes</h2>
                      </Link>
                      <Link href="/ca-real-estate/houses"><Button label="More Listings" theme="secondary" testId={testIds.featuredHomesMoreListings} onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_FEATURED_BUTTON)}/></Link>
                    </div>
                    <HomeListingsModule type="featured" featuredHomesMlsNums={featuredHomesMlsNums} userLocation={userLocation} isCrawler={isCrawler}/>
                  </div>
                }
              </LoadWhenVisible>
            </section>
          }
          <>
            <section className={buildClassName(styles['home-sections'], styles['content-block'], styles['buy-guide'], !isCanadianSite && styles.half)}>
              <LoadWhenVisible onValueChange={() => setIsContentBlockVisible(true)}>
                {!isContentBlockVisible && <LoadingSkeleton/>}
                {isContentBlockVisible && ContentBlock &&
                  <div className={styles['home-block']}>
                    <ContentBlock content={theme.contentBlockBuy} cardEvent={GTM_CLICK_HOMEPAGE_BUYERS_CARD_CLICK} buttonEvent={GTM_CLICK_HOMEPAGE_BUYERS_BUTTON_CLICK} />
                  </div>
                }
              </LoadWhenVisible>
            </section>
            <section className={buildClassName(styles['home-sections'], styles['content-block'], styles['buy-guide'], !isCanadianSite && styles.half)}>
              <LoadWhenVisible onValueChange={() => setIsContentBlockVisible(true)}>
                {!isContentBlockVisible && <LoadingSkeleton />}
                {isContentBlockVisible && ContentBlock &&
                  <div className={styles['home-block']}>
                    <ContentBlock content={theme.contentBlockSell} cardEvent={GTM_CLICK_HOMEPAGE_SELLERS_CARD_CLICK} buttonEvent={GTM_CLICK_HOMEPAGE_SELLERS_BUTTON_CLICK} />
                  </div>
                }
              </LoadWhenVisible>
            </section>
          </>
        </div>
        {isCanadianSite &&
          <section className={buildClassName(styles['home-sections'], styles['find-by-keyword'])} data-testid={testIds.homeKeywords}>
            <div className={styles.container}>
              <LoadWhenVisible onValueChange={() => setIsKeywordsListVisible(true)}>
                {!isKeywordsListVisible && <LoadingSkeleton />}
                {isKeywordsListVisible && KeywordsList &&
                  <div className={styles['home-block']}>
                    <div className={buildClassName(styles.row, styles['find-by-keyword-heading'])} data-testid={testIds.findByKeyword}>
                      <h2>Search by Keywords</h2>
                    </div>
                    <div>
                      <KeywordsList slug={currentSlug} areaName={currentAreaName} latitude={currentLatitude} longitude={currentLongitude} subLocation={currentSublocation} />
                    </div>
                  </div>
                }
              </LoadWhenVisible>
            </div>
          </section>
        }
      </section>
      <InternalLinksContainer isCrawler={ isCrawler } data={internalLinksData} isHomepage={ true } />
    </Layout>
  );
}
