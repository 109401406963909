import React, { useEffect, useState, useRef } from 'react';
import Layout from 'components/layout';
import useQueryParams from 'hooks/use-query-params';
import AnimatedCarousel from 'components/animated-carousel';
import { useModalContext } from 'contexts/modal';
import { buildClassName } from 'utils/build-class-name';
import { trackEvent } from 'utils/google-tag-manager';
import styles from '../style.module.scss';
import { DEFAULT_LISTING_PARAMS_US } from 'contexts/preferences/listing-params/defaults';
import NotificationBanner from 'components/notification-banner';
import { useUserContext, usePreferencesContext, useThemeContext } from 'contexts';
import { testIds } from 'constants/test-constants';
import { generateSlugAndCoordinatesFromLastSearch, generateUrlsFromLastSearch, LastSearchSlugAndCoordsResponse, LastSearchUrlsResponse } from 'data/search-predictions';
import { ModalOpeners } from 'types/modal';
import LoadWhenVisible from 'components/load-when-visible';
import LoadingSkeleton from 'components/loading-skeleton';
import dynamic from 'next/dynamic';
import ExpForSaleSection from '../themed-for-sale-section/exp';
import VideoReveal from 'components/video-reveal';
import VideoRevealMobile from 'components/video-reveal/mobile';
import { useIsDesktop } from 'hooks/use-size-class';
import { useTrackExpOnlyEvents } from 'hooks/use-track-exp-only-events';
import { GTM_EXP_LEARN_MORE_BUTTON_CLICK } from 'constants/events';

import type { HomePageProps } from '../index';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';
import InternalLinksExp from '../internal-links-exp';

export default function HomePageExpUS({ isCrawler, isNotificationBannerHidden, availableHomeListings, availableHomeListingsMeta, availableHomeListingsLocation, isExpandedArea, internalLinksData }: HomePageProps) {
  const { userLocation, siteLocation } = useUserContext();
  const { lastSearchLocation } = usePreferencesContext();
  const { openModal } = useModalContext();
  const { theme } = useThemeContext();
  const isDesktop = useIsDesktop();
  const { trackExpEvent } = useTrackExpOnlyEvents();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [homesForSaleUrl, setHomesForSaleUrl] = useState(`/${siteLocation?.toLowerCase()}-real-estate`);
  const [mapViewUrl, setMapViewUrl] = useState(`/search?latitude=${DEFAULT_LISTING_PARAMS_US.filter.latitude}&longitude=${DEFAULT_LISTING_PARAMS_US.filter.longitude}&area-name=${DEFAULT_LISTING_PARAMS_US.filter.areaName}&slug=${DEFAULT_LISTING_PARAMS_US.filter.slug}`);
  const [isKeywordsListVisible, setIsKeywordsListVisible] = useState(false);
  const [isExpAwardsVisible, setIsExpAwardsVisible] = useState(false);
  const [isExpJoinVisible, setIsExpJoinVisible] = useState(false);
  const [isExpContentCardsVisible, setIsExpContentCardsVisible] = useState(false);
  const [isVideoRevealVisible, setIsVideoRevealVisible] = useState(false);
  const [isExpPromotionCardVisible, setIsExpPromotionCardVisible] = useState(false);
  const [currentSlug, setCurrentSlug] = useState(DEFAULT_LISTING_PARAMS_US.filter.slug);
  const [currentAreaName, setCurrentAreaName] = useState(DEFAULT_LISTING_PARAMS_US.filter.areaName);
  const [currentLatitude, setCurrentLatitude] = useState(DEFAULT_LISTING_PARAMS_US.filter.latitude);
  const [currentLongitude, setCurrentLongitude] = useState(DEFAULT_LISTING_PARAMS_US.filter.longitude);
  const [currentSublocation, setCurrentSublocation] = useState('');
  const [ExpAwardCarousel, setExpAwardCarousel] = useState<any>();
  const [ExpJoinCarousel, setExpJoinCarousel] = useState<any>();
  const [ExpContentCards, setExpContentCards] = useState<any>();
  const [ExpPromotionCard, setExpPromotionCard] = useState<any>();
  const [KeywordsList, setKeywordsList] = useState<any>();

  const generateCoordinatesAndSlug = async () => {
    if (lastSearchLocation) {
      generateSlugAndCoordinatesFromLastSearch(lastSearchLocation).then((response: LastSearchSlugAndCoordsResponse) => {
        setCurrentSlug(response?.slug || currentSlug);
        setCurrentAreaName(lastSearchLocation.description);
        setCurrentLatitude(response?.latitude || currentLatitude);
        setCurrentLongitude(response?.longitude || currentLongitude);
        setCurrentSublocation(response?.subLocation);
      });
    }
    else if (userLocation && userLocation.countryCode === 'US') {
      setCurrentSlug(userLocation.slug);
      setCurrentAreaName(userLocation.name);
      setCurrentLatitude(userLocation.latitude);
      setCurrentLongitude(userLocation.longitude);
      setCurrentSublocation('');
    }
    else {
      setCurrentSlug(defaultUSCityPayload.slug);
      setCurrentAreaName(defaultUSCityPayload.name);
      setCurrentLatitude(defaultUSCityPayload.latitude);
      setCurrentLongitude(defaultUSCityPayload.longitude);
      setCurrentSublocation('');
    }
  };

  const generateUrls = async () => {
    if (lastSearchLocation) {
      generateUrlsFromLastSearch(lastSearchLocation).then((response: LastSearchUrlsResponse) => {
        setHomesForSaleUrl(response?.homesForSaleUrl || homesForSaleUrl);
        setMapViewUrl(response?.mapViewUrl || mapViewUrl);
      });
    } else if (userLocation && userLocation.countryCode === 'US') {
      const updatedHomeUrl = `/${userLocation.slug}-real-estate`;
      setHomesForSaleUrl(updatedHomeUrl);
      setMapViewUrl(`/search?latitude=${userLocation?.latitude}&longitude=${userLocation?.longitude}&area-name=${userLocation?.name}&slug=${userLocation?.slug}`);
    } else if (userLocation) {
      // fall back to country default
      const fallbackLocation = siteLocation === 'US' ? defaultUSCityPayload : defaultCACityPayload;
      const updatedHomeUrl = `/${fallbackLocation.slug}-real-estate`;
      setHomesForSaleUrl(updatedHomeUrl);
      setMapViewUrl(`/search?latitude=${fallbackLocation?.latitude}&longitude=${fallbackLocation?.longitude}&area-name=${fallbackLocation?.name}&slug=${fallbackLocation?.slug}`);
    }
  };

  useQueryParams(params => openModal('reset-password', params), ModalOpeners.RESET_PASSWORD_TOKEN, ModalOpeners.EMAIL);
  useQueryParams(params => openModal('confirm-account', params), ModalOpeners.CONFIRMATION_TOKEN, ModalOpeners.USER_AGENT);
  useQueryParams(params => openModal('login-registration', params), ModalOpeners.SIGNIN_EMAIL);
  useQueryParams(params => openModal('login-registration', params), ModalOpeners.WARNING_MESSAGE);
  useQueryParams(params => openModal('notification', { message: params.notification as string }), ModalOpeners.NOTIFICATION);

  useEffect(() => {
    generateUrls();
    generateCoordinatesAndSlug();
    trackEvent('UiHomePView');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isKeywordsListVisible && !KeywordsList) {
      setKeywordsList(dynamic(import('components/home-page/keywords-list')));
    }
    if (isExpAwardsVisible && !ExpAwardCarousel) {
      setExpAwardCarousel(dynamic(import('components/home-page/exp-components/award-carousel')));
    }
    if (isExpJoinVisible && !ExpJoinCarousel) {
      setExpJoinCarousel(dynamic(import('components/home-page/exp-components/join-exp')));
    }
    if (isExpContentCardsVisible && !ExpContentCards) {
      setExpContentCards(dynamic(import('components/home-page/content-cards')));
    }
    if (isExpPromotionCardVisible && !ExpPromotionCard) {
      setExpPromotionCard(dynamic(import('components/home-page/exp-components/large-promotion-card')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKeywordsListVisible, isExpAwardsVisible, isExpJoinVisible, isExpContentCardsVisible, isExpPromotionCardVisible]);

  return (
    <Layout>
      <NotificationBanner label="New Construction Homes are here!" link="/newhomes" isHidden={isNotificationBannerHidden} />
      <AnimatedCarousel themeName='exprealtyUS' scrollRef={scrollRef}/>
      <div className={styles['exp-homes-sale-divider']}/>
      <section className={styles.component}>
        <div className={buildClassName(styles['home-modules'], styles['exp-home'])} ref={scrollRef}>
          <ExpForSaleSection isCrawler={isCrawler} availableHomeListings={availableHomeListings} availableHomeListingsMeta={availableHomeListingsMeta} availableHomeListingsLocation={availableHomeListingsLocation} isExpandedArea={isExpandedArea} homesForSaleUrl={homesForSaleUrl} mapViewUrl={mapViewUrl} />
        </div>
        <section className={styles['home-sections']}>
          <LoadWhenVisible onValueChange={() => setIsExpAwardsVisible(true)}>
            {isExpAwardsVisible && ExpAwardCarousel && <ExpAwardCarousel/>}
          </LoadWhenVisible>
        </section>
        <div className={buildClassName(styles['home-modules'], styles['exp-home'])}>
          <>
            <section className={styles['home-sections']}>
              <LoadWhenVisible onValueChange={() => setIsExpJoinVisible(true)}>
                {isExpJoinVisible && ExpJoinCarousel && <ExpJoinCarousel link={theme.organization.joinSectionUrl}/>}
              </LoadWhenVisible>
            </section>
            <div className={styles['exp-sold-price-divider']}/>
            <section className={styles['home-sections']}>
              <LoadWhenVisible onValueChange={() => setIsExpContentCardsVisible(true)}>
                {isExpContentCardsVisible && ExpContentCards && <ExpContentCards/>}
              </LoadWhenVisible>
            </section>
          </>
          <div className={styles['exp-sold-price-divider']}/>
        </div>
        <LoadWhenVisible onValueChange={() => setIsVideoRevealVisible(true)}>
          {isVideoRevealVisible && isDesktop && <VideoReveal/>}
          {isVideoRevealVisible && !isDesktop && <VideoRevealMobile/>}
        </LoadWhenVisible>
        <section className={styles['home-sections']}>
          <LoadWhenVisible onValueChange={() => setIsExpPromotionCardVisible(true)}>
            {isExpPromotionCardVisible && ExpPromotionCard && <ExpPromotionCard onClickLearnMoreButton={() => trackExpEvent(GTM_EXP_LEARN_MORE_BUTTON_CLICK)}/>}
          </LoadWhenVisible>
        </section>
        <section className={buildClassName(styles['home-sections'], styles['find-by-keyword-exp'])} data-testid={testIds.homeKeywords}>
          <div className={styles.container}>
            <LoadWhenVisible onValueChange={() => setIsKeywordsListVisible(true)}>
              {!isKeywordsListVisible && <LoadingSkeleton/>}
              {isKeywordsListVisible && KeywordsList &&
                <div className={buildClassName(styles['home-block'], styles['exp-remove-btm-padding'])}>
                  <div className={buildClassName(styles.row, styles['find-by-keyword-heading'])} data-testid={testIds.findByKeyword}>
                    <h2>Search by <b>Keywords</b></h2>
                  </div>
                  <div className={styles['keywords-list']}>
                    <KeywordsList slug={currentSlug} areaName={currentAreaName} latitude={currentLatitude} longitude={currentLongitude} subLocation={currentSublocation} />
                  </div>
                </div>
              }
            </LoadWhenVisible>
          </div>
        </section>
      </section>
      <InternalLinksExp isCrawler={isCrawler} data={internalLinksData}/>
    </Layout>
  );
}
