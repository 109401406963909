import React from 'react';

interface Props {
  fill?: string;
}

const VolumeIcon = ({ fill }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="69" viewBox="0 0 74 69" fill="none">
      <circle cx="37" cy="35" r="20" fill={fill}/>
      <path d="M36 28L31 32H27V38H31L36 42V28Z" fill="#F3F3F4" stroke="#0C0F24" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M46 32L40 38" stroke="#0C0F24" strokeWidth="1.5" strokeLinecap="square"/>
      <path d="M40 32L46 38" stroke="#0C0F24" strokeWidth="1.5" strokeLinecap="square"/>
    </svg> 
  );
};

export default VolumeIcon;
